export function sensor(data) {
    if (data === 'upstream') return {
        location: 'upstream',
        flow: 15.8,
        velocity: 0.54,
        cross: 32.07,
        waterlevel: 3.84,
        temp: 49.17,
        humidity: 37.84,
        batt: 12.50,
        signal: -95,
        timestamp: 1662044372,
        link: '/station/upstream'
    };
    if (data === 'downstream') return {
        location: 'downstream',
        flow: 15.8,
        velocity: 0.54,
        cross: 32.07,
        waterlevel: 3.84,
        temp: 49.17,
        humidity: 37.84,
        batt: 12.50,
        signal: -95,
        timestamp: 1662044372,
    };

}

export function station() {
    return {
        upstream: {
            tag: 'upstream',
            name: 'จุดตัดลำน้ำใกล้บ่อพักน้ำ (Upstream)',
            latitude: 13.684481,
            longitude: 101.470941,
            type: 'วัดปริมาณน้ำ',
            link: '/station/upstream'
        },
        downstream: {
            tag: 'downstream',
            name: 'จุดตัดลำน้ำสะพานเหล็ก (Downstream)',
            latitude: 13.683868,
            longitude: 101.467751,
            type: 'วัดปริมาณน้ำ',
            link: '/station/downstream'
        }
    }
}

export function chartData(a) {
    const padding = [
        { name: "flow", add: 15, multi: 1 },
        { name: "velocity", add: 0.5, multi: 0.1 },
        { name: "cross", add: 40, multi: 1 },
        { name: "waterlevel", add: 4, multi: 0.5 },
        { name: "temp", add: 45, multi: 1 },
        { name: "humidity", add: 40, multi: 1 },
        { name: "batt", add: 12.5, multi: 0.1 },
        { name: "signal", add: 95, multi: 0.1 },
    ];
    const index = padding.findIndex((x) => x.name === a);
    const pad = padding[index];

    let data = [];
    for (let i = 1; i < 25; i++) {
        data.push({
            time: i,
            value: (Math.random() * pad.multi + pad.add).toFixed(1),
        });
        if (i === 24) return data;
    }
}

export function dailyData() {
    const padding = [
        { name: "flow", add: 15, multi: 1 },
        { name: "velocity", add: 0.5, multi: 0.1 },
        { name: "cross", add: 40, multi: 1 },
        { name: "waterlevel", add: 4, multi: 0.5 },
        { name: "temp", add: 45, multi: 1 },
        { name: "humidity", add: 40, multi: 1 },
        { name: "batt", add: 12.5, multi: 0.1 },
        { name: "signal", add: 95, multi: 0.1 },
    ];
    let dd = new Date();

    dd.setMinutes(Math.floor(dd.getMinutes() / 5) * 5);
    dd.setSeconds(0);
    const data = [];
    for (let i = 0; i < 30; i++) {
        const fiveMinutesInMillis = 5 * 60 * 1000;
        const reducer = i === 0 ? 0 : fiveMinutesInMillis;
        dd.setTime(dd.getTime() - reducer)
        data.push({
            date: dd.toLocaleString('en-GB'),
            flow: (Math.random() * padding[0].multi + padding[0].add).toFixed(2),
            velocity: (Math.random() * padding[1].multi + padding[1].add).toFixed(2),
            cross: (Math.random() * padding[2].multi + padding[2].add).toFixed(2),
            waterlevel: (Math.random() * padding[3].multi + padding[3].add).toFixed(2),
            temp: (Math.random() * padding[4].multi + padding[4].add).toFixed(2),
            humidity: (Math.random() * padding[5].multi + padding[5].add).toFixed(2),
        })
        if (i === 29) return data;
    }
}