import axios from "axios";
import React, { useState, useEffect } from "react";
import Recharts from "./Recharts";

const StationTab1 = (props) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm">
          <table className="table">
            <thead className="table-dark">
              <tr>
                <th scope="col">Sensor</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Flow Rate</th>
                <td>
                  {props.data.flow} m<sup>2</sup>/s
                </td>
              </tr>
              <tr>
                <th>Velocity</th>
                <td>{props.data.velocity} m/s</td>
              </tr>
              <tr>
                <th>Cross Section</th>
                <td>
                  {props.data.cross} m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th>Water Level</th>
                <td>{props.data.waterlevel} m</td>
              </tr>
              <tr>
                <th>Temperature</th>
                <td>{props.data.temp} (&#8451;)</td>
              </tr>
              <tr>
                <th>Humidity</th>
                <td>{props.data.humidity} %</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-sm">
          <table className="table">
            <thead className="table-dark">
              <tr>
                <th scope="col">Sensor</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Battery Voltage</th>
                <td>{props.data.batt} V</td>
              </tr>
              <tr>
                <th>Signal Level</th>
                <td>{props.data.signal} dB</td>
              </tr>
              <tr>
                <th>Last Update</th>
                <td>{new Date(props.data.date).toLocaleString("en-GB")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="titlediv">Flow Rate</div>
          <Recharts location={props.data.location} data="flow" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="titlediv">Velocity</div>
          <Recharts location={props.data.location} data="velocity" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="titlediv">Cross Section</div>
          <Recharts location={props.data.location} data="cross" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="titlediv">Water Level</div>
          <Recharts location={props.data.location} data="waterlevel" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="titlediv">Temperature</div>
          <Recharts location={props.data.location} data="temp" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="titlediv">Humidity</div>
          <Recharts location={props.data.location} data="humidity" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="titlediv">Battery Voltage</div>
          <Recharts location={props.data.location} data="batt" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="titlediv">Signal Level</div>
          <Recharts location={props.data.location} data="signal" />
        </div>
      </div>
    </div>
  );
};

export default StationTab1;
