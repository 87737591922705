import React, { useState, useEffect } from "react";
import axios from "axios";

import { dailyData } from "../data/fakedata";
import { useSearchParams } from "react-router-dom";

const StationTab2 = (props) => {
  const [data, setData] = useState([]);
  useEffect(async () => {
    const apiUrl =
      process.env.REACT_APP_BASEURL + "/report/" + props.data.location;
    if (props.data.location) {
      const response = await axios.get(apiUrl);
      setData(response.data.message);
    }
  }, [props]);

  return (
    <div className="tab4">
      <table className="table">
        <thead className="table-dark">
          <tr>
            <th scope="col">{props.data.location}</th>
            <th scope="col">
              Flow Rate (m<sup>2</sup>/s)
            </th>
            <th scope="col">Velocity (m/s)</th>
            <th scope="col">
              Cross Section (m<sup>2</sup>)
            </th>
            <th scope="col">Water Level (m)</th>
            <th scope="col">Temperature (&#8451;)</th>
            <th scope="col">Humidity (%)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            return (
              <tr key={item.date}>
                <td>{item.date}</td>
                <td>{item.flow}</td>
                <td>{item.velocity}</td>
                <td>{item.cross}</td>
                <td>{item.waterlevel}</td>
                <td>{item.temp}</td>
                <td>{item.humidity}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StationTab2;
