import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { dailyData } from "../data/fakedata";
import { typeImplementation } from "@testing-library/user-event/dist/type/typeImplementation";

const StationTab4 = (props) => {
  const [dateArray, setDateArray] = useState([]);
  useEffect(async () => {
    let tmp = [];
    for (let i = 0; i < 30; i++) {
      let d = new Date();
      d.setDate(d.getDate() - i);
      tmp.push(d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate());
      if (i === 29) setDateArray(tmp);
    }
  }, []);
  let evenGrid = false;
  return (
    <div className="stationtab4">
      <div className="container">
        {dateArray.map((item) => {
          evenGrid = !evenGrid;
          return (
            <div className={`row ${evenGrid ? "odd" : "even"}`} key={item}>
              <div className="col-sm">
                {new Date(item).toLocaleDateString("en-GB")}
              </div>
              <div className="col-sm">
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  onClick={() => toExcel(item, props.data.location)}
                >
                  Download As Excel
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StationTab4;

async function toExcel(date, location) {
  const apiUrl =
    process.env.REACT_APP_BASEURL + "/report/" + location + "?d=" + date;
  console.log(apiUrl);
  const response = await axios.get(apiUrl);
  const worksheet = XLSX.utils.json_to_sheet(response.data.message);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, "DataSheet.xlsx");
}

async function renderButton(date, location, even) {
  const apiUrl =
    process.env.REACT_APP_BASEURL + "/report/" + location + "?d=" + date;
  console.log(apiUrl);
  const response = await axios.get(apiUrl);
  if (response.data != []) {
    return (
      <div className={`row ${even ? "odd" : "even"}`} key={date}>
        <div className="col-sm">
          {new Date(date).toLocaleDateString("en-GB")}
        </div>
        <div className="col-sm">
          <button
            type="button"
            className="btn btn-success btn-sm"
            onClick={() => toExcel(date, location)}
          >
            Download As Excel
          </button>
        </div>
      </div>
    );
  }
}
