import React, { useState, useEffect } from "react";
import { sensor, station } from "../data/fakedata";
import { NavLink } from "react-router-dom";

const Station = (props) => {
  return (
    <div className="stationpage table-responsive">
      <table className="table table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">ชื่อสถานี</th>
            <th scope="col">
              Flow (m<sup>2</sup>/s)
            </th>
            <th scope="col">Velocity (m/s)</th>
            <th scope="col">
              Cross Section (m<sup>2</sup>)
            </th>
            <th scope="col">Water Level (m)</th>
            <th scope="col">Temperature (&#8451;)</th>
            <th scope="col">Humidity (%)</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => {
            return (
              <tr key={index}>
                <th scope="row">
                  <NavLink to={item.link}>{item.name}</NavLink>
                </th>
                <td>{item.flow}</td>
                <td>{item.velocity}</td>
                <td>{item.cross}</td>
                <td>{item.waterlevel}</td>
                <td>{item.temp}</td>
                <td>{item.humidity}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Station;
