export function popupCard(data) {
    const clock = new Date(data.timestamp).toLocaleString('en-GB')
    const date = new Date(data.date).toLocaleString('en-GB')
    return '<div class="popupcard">' +
        '<div class="titlediv"><i class="bi bi-circle-fill ' + data.badge + '"></i>' + data.name + '</div>' +
        '<div class="sensordiv">' +
        '<span>ข้อมูลเซ็นเซอร์</span>' +
        '<table class="table">' +
        '  <thead class="thead-dark">' +
        '    <tr>' +
        '      <th scope="col">Sensor</th>' +
        '      <th scope="col">Value</th>' +
        '  </thead>' +
        '  <tbody>' +
        '    <tr>' +
        '      <th>Flow Rate</th>' +
        '      <td>' + data.flow + ' m<sup>2</sup>/s</td>' +
        '    </tr>' +
        '    <tr>' +
        '      <th>Velocity</th>' +
        '      <td>' + data.velocity + ' m/s</td>' +
        '    </tr>' +
        '    <tr>' +
        '      <th>Cross Section</th>' +
        '      <td>' + data.cross + ' m<sup>2</sup></td>' +
        '    </tr>' +
        '    <tr>' +
        '      <th>Water Level</th>' +
        '      <td>' + data.waterlevel + ' m</td>' +
        '    </tr>' +
        '    <tr>' +
        '      <th>Temperature</th>' +
        '      <td>' + data.temp + ' c</td>' +
        '    </tr>' +
        '    <tr>' +
        '      <th>Humidity</th>' +
        '      <td>' + data.humidity + ' %</td>' +
        '    </tr>' +
        '  </tbody>' +
        '</table>' +
        '</div>' +
        '<div class="devicediv">' +
        '<span>ข้อมูลอุปกรณ์</span>' +
        '<table class="table">' +
        '  <thead class="thead-dark">' +
        '    <tr>' +
        '      <th scope="col">Sensor</th>' +
        '      <th scope="col">Value</th>' +
        '  </thead>' +
        '  <tbody>' +
        '    <tr>' +
        '      <th>Battery Voltabe</th>' +
        '      <td>' + data.batt + ' V</td>' +
        '    </tr>' +
        '    <tr>' +
        '      <th>Signal Level</th>' +
        '      <td>' + data.signal + ' dB</td>' +
        '    </tr>' +
        '  </tbody>' +
        '</table>' +
        '</div>' +
        '<div class="footerdiv">Device Clock: ' + clock + '<br>Last Update: ' + date + '</div>' +
        '<div class="linkdiv"><a href="' + data.link + '">ดูรายละเอียดสถานี</a></div>' +
        '<div class="bgdiv"></div>' +
        '</div>';
}