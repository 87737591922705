import React, { useState, useEffect } from "react";
import StationTab1 from "./StationTab1";
import StationTab2 from "./StationTab2";
import StationTab3 from "./StationTab3";
import StationTab4 from "./StationTab4";

const StationDetail = (props) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    let tempData = pageData;
    const d = props.data;
    const l = props.location;
    const index = props.data.map((item) => item.location).indexOf(l);
    tempData = { ...tempData, ...d[index] };
    setPageData(tempData);
  }, [props]);

  return (
    <div className="stationdetail">
      <h5>{pageData.name}</h5>
      <ul className="nav nav-tabs navtabs">
        <li className="nav-item">
          <a
            className={`nav-link ${selectedTab === 1 ? "active" : ""}`}
            href="#"
            onClick={() => {
              setSelectedTab(1);
            }}
          >
            ข้อมูลการตรวจวัด
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${selectedTab === 2 ? "active" : ""}`}
            href="#"
            onClick={() => {
              setSelectedTab(2);
            }}
          >
            ข้อมูลประจำวัน
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${selectedTab === 3 ? "active" : ""}`}
            href="#"
            onClick={() => {
              setSelectedTab(3);
            }}
          >
            ข้อมูลสถานี
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${selectedTab === 4 ? "active" : ""}`}
            href="#"
            onClick={() => {
              setSelectedTab(4);
            }}
          >
            ดาวน์โหลด
          </a>
        </li>
      </ul>
      <div className="stationcontent">{renderPage(selectedTab, pageData)}</div>
    </div>
  );
};

export default StationDetail;

function renderPage(tabIndex, data) {
  if (tabIndex === 1) return <StationTab1 data={data} />;
  if (tabIndex === 2) return <StationTab2 data={data} />;
  if (tabIndex === 3) return <StationTab3 data={data} />;
  if (tabIndex === 4) return <StationTab4 data={data} />;
}
