import React, { useState, useEffect } from "react";
import { sensor, station } from "./data/fakedata";
import axios from "axios";

import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import { PageLayout } from "./components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Button from "react-bootstrap/Button";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import Iws from './components/iws';
import Longdo from "./components/Longdoo";
import SideMenu from "./components/SideMenu";
import Station from "./components/Station";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import StationDetail from "./components/StationDetail";

const Videos = () => {
    return <h1>Content/Videos</h1>
}

function App() {
    const [inactive, setInactive] = useState(true);
    const [sensorData, setSensorData] = useState(null);
    useEffect(() => {
        const dup = { ...sensor("upstream"), ...station().upstream };
        const ddn = { ...sensor("downstream"), ...station().downstream };
        const apiUrl = process.env.REACT_APP_BASEURL + '/latest'
        axios.get(apiUrl)
            .then(response => {
                setSensorData(response.data)
            })
    }, []);

    if (!sensorData) return null;
    return (
        <div className="App">
            <Router>
                <SideMenu onCollapse={(inactive) => {
                    setInactive(inactive);
                }} />
                <div className={`heading ${inactive ? 'inactive' : ''}`}>
                    <h4>ระบบโทรมาตร บริษัท อินดัสเตรียล วอเตอร์ ซัพพลาย จำกัด</h4>
                </div>
                <div className={`maincontent ${inactive ? 'inactive' : ''}`}>
                    <Routes>
                        <Route path={'/station/downstream'} element={<StationDetail data={sensorData} location='downstream' />} />
                        <Route path={'/station/upstream'} element={<StationDetail data={sensorData} location='upstream' />} />
                        <Route path={'/station/'} element={<Station data={sensorData} />} />
                        <Route path={'/'} element={<Longdo data={sensorData} />} />

                    </Routes>
                </div>

            </Router>

        </div>

    );
}

function ProfileContent() {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    const name = accounts[0] && accounts[0].name;

    function RequestProfileData() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraph(response.accessToken).then(response => setGraphData(response));
            });
        });
    }

    return (
        <h5 className="card-title">Welcome {name}</h5>

    );
};

export default App;