import React, { Component } from 'react';
import axios from 'axios';
import { popupCard } from './PopupCard'
import { sensor, station } from "../data/fakedata";

export let longdo;
export let map;

export class LongdoMap extends Component {

    constructor(props) {
        super(props);
        this.mapCallback = this.mapCallback.bind(this);
    }
    mapCallback(data) {


        longdo = window.longdo
        map = new window.longdo.Map({
            placeholder: document.getElementById(this.props.id),
            language: 'en'
        });
        var markerDown = new longdo.Marker({ lon: 101.467751, lat: 13.683868 },
            {
                title: 'Downstream',
                icon: {
                    html: '<div class="spinner-grow ' + this.badgeStatus(this.props.data.find(x => x.location === 'downstream').timestamp) + '" role="status"></div>',
                    offset: { x: 18, y: 21 }
                },
                kk: 'haha'
            });
        var markerUp = new longdo.Marker({ lon: 101.470941, lat: 13.684481 },
            {
                title: 'Uptream',
                icon: {
                    html: '<div class="spinner-grow ' + this.badgeStatus(this.props.data.find(x => x.location === 'upstream').timestamp) + '" role="status"></div>',
                    offset: { x: 18, y: 21 }
                },
                kk: 'haha'
            });

        map.location({ lon: 101.469425, lat: 13.684410 }, true);
        map.Overlays.add(markerDown);
        map.Overlays.add(markerUp);
        map.zoom(18, true);
        map.Ui.DPad.visible(false);
        //map.Layers.add(longdo.Layers.THAICHOTE);
        map.Event.bind('overlayClick', async function (overlay) {

            const current = Date.parse(new Date());
            const xx = await fetchData();
            const xup = xx.find(item => item.location === 'upstream');
            const diffUp = current - Date.parse(xup.timestamp);
            const xdn = xx.find(item => item.location === 'downstream');
            const diffDown = current - Date.parse(xdn.timestamp);
            xup.badge = diffUp > -500000 ? 'circle-up' : 'circle-down';
            xdn.badge = diffDown > -500000 ? 'circle-up' : 'circle-down';
            const dup = { ...sensor("upstream"), ...station().upstream };
            const ddn = { ...sensor("downstream"), ...station().downstream };
            var popupDown = new longdo.Popup({ lon: 101.467751, lat: 13.683868 },
                {
                    html: popupCard(xdn)
                });

            var popupUp = new longdo.Popup({ lon: 101.470941, lat: 13.684481 },
                {
                    html: popupCard(xup)
                });

            var pointer = overlay.location();
            if (pointer.lon === 101.467751) map.Overlays.add(popupDown)
            if (pointer.lon === 101.470941) map.Overlays.add(popupUp)
        });

        map.Event.bind('click', function () {
            const ovl = map.Overlays.list();
            ovl.forEach(overlay => {
                if (overlay instanceof longdo.Popup) {
                    map.Overlays.remove(overlay)
                }
            })
        });
    }

    badgeStatus(date) {
        const current = Date.parse(new Date());
        const diff = current - Date.parse(date);
        return diff > -500000 ? 'text-success' : 'text-secondary';

    }

    componentDidMount() {

        const existingScript = document.getElementById('longdoMapScript');
        const callback = this.props.callback
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = `https://api.longdo.com/map/?key=${this.props.mapKey}`;
            script.id = 'longdoMapScript';
            document.body.appendChild(script);

            script.onload = () => {
                this.mapCallback();
                if (callback) callback();
            };
        }

        if (existingScript) this.mapCallback(this.props.data);
        if (existingScript && callback) callback();
    }


    render() {

        return (
            <div id={this.props.id} style={{ width: '100%', height: '100%' }}>

            </div>
        );
    }

}

const fetchData = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_BASEURL + '/latest')
        const data = response.data
        return data
    }
    catch (e) {
        return null
    }
}