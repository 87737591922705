import React, { useState, Component } from "react";
import { longdo, map, LongdoMap } from "../middleware/LongdoMap";

const Longdoo = (props) => {
  const mapKey = "07d5c14caef038f8654d7f06de96bb5e";
  return (
    <div className="longdomap">
      <LongdoMap
        id="longdo"
        mapKey={mapKey}
        callback={initMap}
        data={props.data}
      />
    </div>
  );
};

export default Longdoo;

function initMap() {
  map.Layers.setBase(longdo.Layers.THAICHOTE);
}
