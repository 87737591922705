import React, { useState, useEffect } from "react";

const StationTab3 = (props) => {
  const [station, setStation] = useState({});
  useEffect(() => {
    setStation({
      name: props.data.name,
      latitude: props.data.latitude,
      longitude: props.data.longitude,
      type: props.data.type,
      link: props.data.link,
      gmaps:
        "https://www.google.com/maps/search/?api=1&query=" +
        props.data.latitude +
        "%2C" +
        props.data.longitude,
    });
  }, []);
  return (
    <table className="table">
      <tbody>
        <tr>
          <th>Name</th>
          <td>{station.name}</td>
        </tr>
        <tr>
          <th>Type</th>
          <td>{station.type}</td>
        </tr>
        <tr>
          <th>Latitude</th>
          <td>{station.latitude}</td>
        </tr>
        <tr>
          <th>Longitude</th>
          <td>{station.longitude}</td>
        </tr>
        <tr>
          <th>Location</th>
          <td>
            <a href={station.gmaps} target="_blank">
              View in Google Maps
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default StationTab3;
