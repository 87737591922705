import React, { useState, useEffect } from "react";
import axios from "axios";
//import { chartData } from "../data/fakedata";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Label,
} from "recharts";

const Recharts = (props) => {
  const [chartData, setChartData] = useState(null);
  useEffect(async () => {
    const apiUrl =
      process.env.REACT_APP_BASEURL +
      "/chart/" +
      props.location +
      "/" +
      props.data;
    if (props.location && props.data) {
      const response = await axios.get(apiUrl);
      await setChartData(response.data.message);
    }
  }, [props]);
  return (
    <ResponsiveContainer height={300}>
      <LineChart
        data={chartData}
        margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
      >
        <Line type="monotone" dataKey="value" stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="date">
          <Label value="Hour" offset={0} position="bottom" />
        </XAxis>
        <YAxis
          allowDecimals={true}
          type="number"
          domain={["dataMin", "dataMax"]}
        />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Recharts;
