import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import logo from "../assets/logo/npslogo.png";
import user from "../assets/user.jpg";

import MenuItem from "./MenuItem";

const menuItems = [
  {
    name: "หน้าหลัก",
    exact: true,
    to: "/",
    iconClassName: "bi bi-house-door",
  },
  {
    name: "รายละเอียดสถานี",
    exact: true,
    to: "/station",
    iconClassName: "bi bi-newspaper",
    subMenus: [
      { name: "จุดตัดลำน้ำสะพานเหล็ก (Downstream)", to: "/station/downstream" },
      { name: "จุดตัดลำน้ำใกล้บ่อพักน้ำ (Upstream)", to: "/station/upstream" },
    ],
  },
];

const SideMenu = (props) => {
  const [inactive, setInactive] = useState(true);
  useEffect(() => {
    if (inactive) {
      document.querySelectorAll(".sub-menu").forEach((el) => {
        el.classList.remove("active");
      });
    }
    props.onCollapse(inactive);
  }, [inactive]);
  return (
    <div className={`side-menu ${inactive ? "inactive" : ""}`}>
      <div className="top-section">
        <div className="logo">
          <img src={logo} alt="webscript" />
        </div>
        <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
          {inactive ? (
            <i className="bi bi-arrow-right-square-fill"></i>
          ) : (
            <i className="bi bi-arrow-left-square-fill"></i>
          )}
        </div>
      </div>
      <div className="search-controller">
        <button className="search-btn">
          <i className="bi bi-search"></i>
        </button>

        <input type="text" placeholder="ค้นหา" />
      </div>
      <div className="divider"></div>
      <div className="main-menu">
        <ul>
          {menuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              name={menuItem.name}
              exact={menuItem.exact}
              to={menuItem.to}
              iconClassName={menuItem.iconClassName}
              subMenus={menuItem.subMenus || []}
              onClick={() => {
                if (inactive) {
                  setInactive(false);
                }
              }}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
