import React, { useState, useEffect, Component } from 'react';
import socketIOClient from 'socket.io-client'
const ENDPOINT = "https://mqttbroker.advanceagro.net"
//import { client } from '../services/mqttSubscribe';

const Iws = () => {
    const [response, setResponse] = useState([
        {
            topic: 'flowrate',
            display: 'Flow Rate',
            upstream: ['0', 'badge badge-secondary', 'waiting...'],
            downstream: ['0', 'badge badge-secondary', 'waiting...']
        },
        {
            topic: 'velocityrate',
            display: 'Velocity Rate',
            upstream: ['0', 'badge badge-secondary', 'waiting...'],
            downstream: ['0', 'badge badge-secondary', 'waiting...']
        },
        {
            topic: 'crosssection',
            display: 'Cross Section',
            upstream: ['0', 'badge badge-secondary', 'waiting...'],
            downstream: ['0', 'badge badge-secondary', 'waiting...']
        },
        {
            topic: 'waterlevel',
            display: 'Water Level',
            upstream: ['0', 'badge badge-secondary', 'waiting...'],
            downstream: ['0', 'badge badge-secondary', 'waiting...']
        },
        {
            topic: 'temperature',
            display: 'Temperature',
            upstream: ['0', 'badge badge-secondary', 'waiting...'],
            downstream: ['0', 'badge badge-secondary', 'waiting...']
        },
        {
            topic: 'humidity',
            display: 'Humidity',
            upstream: ['0', 'badge badge-secondary', 'waiting...'],
            downstream: ['0', 'badge badge-secondary', 'waiting...']
        },
        {
            topic: 'battvoltage',
            display: 'Battery Voltage',
            upstream: ['0', 'badge badge-secondary', 'waiting...'],
            downstream: ['0', 'badge badge-secondary', 'waiting...']
        },
        {
            topic: 'signallevel',
            display: 'Signal Level',
            upstream: ['0', 'badge badge-secondary', 'waiting...'],
            downstream: ['0', 'badge badge-secondary', 'waiting...']
        },
        {
            topic: 'rtctimestamp',
            display: 'Timestamp',
            upstream: ['0', 'badge badge-secondary', 'waiting...'],
            downstream: ['0', 'badge badge-secondary', 'waiting...']
        }
    ]);

    function handleData (data) {
        const dataArray = data.topic.split('/');
        if(dataArray.indexOf('HOME') === -1) return;
        const location = dataArray[dataArray.indexOf('HOME')+1];
        const topic = dataArray[dataArray.indexOf('HOME')+2];
        let respo = [...response];
        const index = respo.map(item => item.topic).indexOf(topic);
        respo[index][location][0] = data.message;
        respo[index][location][1] = 'badge badge-success';
        respo[index][location][2] = 'updated'
        setResponse(respo);
    }

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);
        socket.on("iwsdashboard", data => {
            handleData(data);
        });
    }, []);
    return ( 
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Upstream</th>
                    <th scope="col">Downstream</th>
                </tr>
            </thead>
            <tbody>
                {response.map(item => <tr key={item.topic}>
                    <th scope="row">{item.display}</th>
                    <td>{item.upstream[0]} <span className={item.upstream[1]}>{item.upstream[2]}</span></td>
                    <td>{item.downstream[0]} <span className={item.downstream[1]}>{item.downstream[2]}</span></td>
                </tr>)}
            </tbody>
        </table>
     );
}
 
export default Iws;